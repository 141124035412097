import { Component, OnInit } from '@angular/core';
import {NodeViewComponent} from '../node-view/node-view.component';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-gene',
  templateUrl: './gene-view.component.html',
  styleUrls: ['./gene-view.component.css',
              '../node-view/node-view.component.css']
})
export class GeneViewComponent extends NodeViewComponent implements OnInit {

  encodes: any;
  tfProteins: any;

  ngOnInit() {
    super.ngOnInit();
    this.loadEncodes();
    this.loadTFs();
  }

  loadTFs() {
    const query = this.network.nodesWithRelationToSourceInGraph(this.nodeUri, environment.relationTypes.tftgRegulates, environment.graphs.tfac2gene, environment.graphs.prot);
    const params = new HttpParams().set('query', query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      if (data.results.bindings[0]) {
        this.tfProteins = data.results.bindings;
      }
    });
  }

  loadEncodes() {
    const query = this.network.nodesWithRelationFromSourceInGraph(this.nodeUri, environment.relationTypes.encodes, environment.graphs.gene, environment.graphs.prot);
    const params = new HttpParams().set('query', query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      if (data.results.bindings[0]) {
        this.encodes = data.results.bindings;
      }
    });
  }
}
