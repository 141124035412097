import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {StatementViewComponent} from './statement-view/statement-view.component';
import {GeneToPhenComponent} from './gene-to-phen/gene-to-phen.component';
import {ProtToGeneComponent} from './prot-to-gene/prot-to-gene.component';
import {ProtToProtComponent} from './prot-to-prot/prot-to-prot.component';
import {GeneViewComponent} from './gene-view/gene-view.component';
import {ProteinViewComponent} from './protein-view/protein-view.component';
import {InfoPageComponent} from './info-page/info-page.component';

const routes: Routes = [
  { path: '', component: InfoPageComponent },
  { path: 'prot/:taxon/:chromosome/:symbol/:id', component: ProteinViewComponent },
  { path: 'gene/:taxon/:chromosome/:symbol', component: GeneViewComponent },
  { path: 'gene-phen/:id', component: GeneToPhenComponent },
  { path: 'prot-gene/:id', redirectTo: 'tfac-gene/:id' },
  { path: 'tfac-gene/:id', component: ProtToGeneComponent },
  { path: 'prot-prot/:id', component: ProtToProtComponent },
  { path: 'prot-onto/:id', component: StatementViewComponent },
  { path: '**', component: InfoPageComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


