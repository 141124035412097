import { Component, OnInit } from '@angular/core';
import {StatementViewComponent} from '../statement-view/statement-view.component';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-prot-to-prot',
  templateUrl: './prot-to-prot.component.html',
  styleUrls: ['./prot-to-prot.component.css']
})
export class ProtToProtComponent extends StatementViewComponent implements OnInit {


  relatedResources: any;

  ngOnInit() {
    this.graphName = '<prot2prot>';
    super.ngOnInit();
    this.loadRelatedResources(this.nodeUri);
  }


  loadRelatedResources(uri: string) {
    const params = new HttpParams().set('query', this.sparql.getRelatedResourcesQuery(uri, this.graphName));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      this.relatedResources = data.results.bindings;
    });
  }
}
