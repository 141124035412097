import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitToArray'
})
export class SplitToArrayPipe implements PipeTransform {

  transform(value: string, separator: string): any {
    const array = [];

    for (const element of value.split(separator)) {
      if (element.length > 0) {
        array.push(element);
      }
    }

    return array;
  }

}
