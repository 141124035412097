import { Component, OnInit } from '@angular/core';
import {StatementViewComponent} from '../statement-view/statement-view.component';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-prot-to-gene',
  templateUrl: './prot-to-gene.component.html',
  styleUrls: ['../statement-view/statement-view.component.css', './prot-to-gene.component.css']
})
export class ProtToGeneComponent extends StatementViewComponent implements OnInit {

  objectKeys = Object.keys;
  objectValues = Object.values;
  abstracts: any = [];
  sources: any = {};
  id: string;
  // tfTaxon: string;
  // tfChromosome: string;
  // tfSymbol: string;
  // tfUniParc: string;
  // tfUri: string;
  // tgTaxon: string;
  // tgChromosome: string;
  // tgSymbol: string;
  // tgUri: string;
  tfSymbol: string;
  tgSymbol: string;
  annotationColors = ['#e9ccff', '#93ff87', '#dcfcff', '#ffe4a5', '#deffb7', '#b9ecff'];


  ngOnInit() {
    this.graphName = '<tfac2gene>';
    super.ngOnInit();
    // :uniParc--:toTaxon

    this.id         = this.route.snapshot.params['id'];
    const idComponents = this.id.split('--');
    const tfId = idComponents[0].split('!');
    const tgId = idComponents[1].split('!');

    if (tfId.length > 1 && tgId.length > 1) {
      this.tfSymbol      = tfId[1];
      this.tgSymbol      = tgId[1];
    } else {
      this.tfSymbol      = idComponents[0];
      this.tgSymbol      = idComponents[1];
    }

    // this.tfUri      = `${environment.baseUri}/gene/${this.tgTaxon}/${this.tgChromosome}/${this.tgSymbol}`;
    // this.tfTaxon    = tfId[0];
    // this.tfChromosome = tfId[1];
    // this.tfSymbol   = tfId[2];
    // this.tfUniParc  = tfId[3]; // http://rdf.biogateway.eu/prot/9606/chr-01/JUN/UPI000000D908
    // this.tfUri      = `${environment.baseUri}/prot/${this.tfTaxon}/${this.tfChromosome}/${this.tfSymbol}/${this.tfUniParc}`;
    //
    // this.tgTaxon    = tgId[0];
    // this.tgChromosome = tgId[1];
    // this.tgSymbol   = tgId[2];
    // this.tgUri      = `${environment.baseUri}/gene/${this.tgTaxon}/${this.tgChromosome}/${this.tgSymbol}`;

  }

  getAbstracts(sourceUri: string) {
    // Get the pubmedId's for the specified source.

    // noinspection UnnecessaryLocalVariableJS
    const source = {
      abstracts: [],
      htmlAbstracts: [],
      uri: sourceUri,
      name: sourceUri.split('#')[1]
    };

    this.sources[sourceUri] = source;

    this.sparql.getUrisWithRelationTo(sourceUri, 'http://semanticscience.org/resource/SIO_000772', this.graphName, false, (data => {
        const results = data.results.bindings;
        for (const result of results) {
          console.log(sourceUri + ' -> ' + result.uri.value);
          const pubmedId = result.uri.value.replace('http://identifiers.org/pubmed/', '');
          this.sparql.getAbstractForPubmedId(pubmedId, (abstractData) => {
            this.abstracts.push(abstractData);
            this.sources[sourceUri].abstracts.push(abstractData);
            if (sourceUri.includes('#extri')) {
              this.sparql.getMatchedSentencesForAbstract(pubmedId, this.tfSymbol, this.tgSymbol, (sentences => {
                let htmlAbstract = abstractData.text;
                let htmlTitle = abstractData.title;

                for (const sentence of sentences) {
                  const index = sentences.indexOf(sentence);
                  let newAbstract = htmlAbstract.replace(sentence.text, '<span title=" Mined with confidence: ' + sentence.score + '" style="background-color:' + this.annotationColors[index % 6] + ';">' + sentence.text + '</span>');
                  if (htmlAbstract === newAbstract) {
                    // No occurences found. Checking if it's the title.
                    const newTitle = htmlTitle.replace(sentence.text, '<span title=" Mined with confidence: ' + sentence.score + '" style="background-color:' + this.annotationColors[index % 6] + ';">' + sentence.text + '</span>');
                    if (newTitle !== htmlTitle) {
                      htmlTitle = newTitle;
                    } else {
                      newAbstract = '<i> Mined sentence not found: ' + sentences[index].text + '</i><br><br>' + htmlAbstract;
                    }
                  }
                  htmlAbstract = newAbstract;
                }
                abstractData.text = htmlAbstract;
                abstractData.title = htmlTitle;

              const scores = sentences.map( a => {
                return a.score;
              });
                abstractData.maxScore = Math.max(...scores);
              this.sources[sourceUri].htmlAbstracts.push(abstractData);
              }));
            } else {
              this.sources[sourceUri].htmlAbstracts.push(abstractData);
            }
          });
        }
    }));



    // Get abstracts for each of the pubmedId's.


    // Get statement data from the MetaDatabase for each of the pubmedId's

    // Create a HTML text with highlited statements.
  }

  loadInstances(uri: string) {
    const params = new HttpParams().set('query', this.sparql.instancesString(uri));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      console.log(data);
      const results = data.results.bindings;
      this.instanceResults = results;
      for (const result of results) {
        const instance = result.instance.value;
        this.getAbstracts(instance);
      }
    });
  }
}
