import { Component, OnInit } from '@angular/core';
import {window} from 'rxjs/internal/operators';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.css'],
})
export class InfoPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // document.location.href = 'https://www.biogateway.eu';
  }

}
