import {Component, Input, OnInit} from '@angular/core';
import {NetworkService} from '../network.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {switchMap} from 'rxjs/internal/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-node-view',
  templateUrl: './node-view.component.html',
  styleUrls: ['./node-view.component.css']
})
export class NodeViewComponent implements OnInit {


  nodeUri: string;

  metadata: any;

  superclassesResults: any;
  instanceResults: any;
  subclassesResults: any;
  memberResults: any;
  parentResults: any;

  nodeLabel: string;
  description: string;
  graphUri: string;

  constructor(public network: NetworkService, public httpClient: HttpClient, private route: ActivatedRoute) { }

  ngOnInit() {

    this.nodeUri = window.location.href.replace('https://', 'http://')
      .replace('localhost:4200', 'rdf.biogateway.eu')
      .replace('test.biogateway', 'rdf.biogateway');

    this.loadSuperClasses(this.nodeUri);
    this.loadInstances(this.nodeUri);
    // this.loadDescription(this.nodeUri);
    this.loadParents(this.nodeUri);
    this.getMetadata(this.nodeUri);
  }


  getMetadata(uri: string) {
    this.network.getNodeMetadata(uri, (metadata => {
      this.metadata = metadata;
    }));
  }

  loadSuperClasses(uri: string) {
    const params = new HttpParams().set('query', this.network.superClassString(uri));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      console.log(data);
      this.superclassesResults = data.results.bindings;
    });
  }

  loadSubClasses(uri: string) {
    const params = new HttpParams().set('query', this.network.subClassString(uri));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      this.subclassesResults = data.result.bindings;
    });
  }

  loadInstances(uri: string) {
    const params = new HttpParams().set('query', this.network.instancesString(uri));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      console.log(data);
      this.instanceResults = data.results.bindings;
    });
  }

  loadDescription(uri: string) {
    const params = new HttpParams().set('query', this.network.labelAndDescriptionString(uri));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      const firstResult = data.results.bindings[0];
      this.nodeLabel = firstResult.label.value;
      this.description = firstResult.definition.value;
      this.graphUri = firstResult.graph.value;
    });
  }

  loadMembers(uri: string) {
    const params = new HttpParams().set('query', this.network.protMembersString(uri));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      console.log(data);
      this.memberResults = data.results.bindings;
    });
  }

  loadParents(uri: string) {
    const params = new HttpParams().set('query', this.network.memberOfProtString(uri));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      console.log(data);
      this.parentResults = data.results.bindings;
    });
  }

}
