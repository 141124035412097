import { Component, OnInit } from '@angular/core';
import {StatementViewComponent} from '../statement-view/statement-view.component';

@Component({
  selector: 'app-prot-to-phen',
  templateUrl: '../statement-view/statement-view.component.html',
  styleUrls: ['../statement-view/statement-view.component.css']
})
export class GeneToPhenComponent extends StatementViewComponent implements OnInit {

  ngOnInit() {
    this.graphName = '<gene2phen>';
    super.ngOnInit();
  }

}
