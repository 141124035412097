import {Component, Input, OnInit} from '@angular/core';
import {NetworkService} from '../network.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-node-fragment',
  templateUrl: './node-fragment.component.html',
  styleUrls: ['./node-fragment.component.css']
})
export class NodeFragmentComponent implements OnInit {

  @Input() nodeUri: string;
  nodeType = 'unspecified';

  label: string;
  metadata: any;
  tooltipText = '';
  description = '';
  synonymList = '';

  constructor(private network: NetworkService, public sparql: NetworkService, public httpClient: HttpClient) {}

  ngOnInit() {
    if (this.nodeUri.startsWith('http://rdf.biogateway.eu/prot/')) {
      this.nodeType = 'protein';
    } else if (this.nodeUri.startsWith('http://rdf.biogateway.eu/gene/')) {
      this.nodeType = 'gene';
    } else if (this.nodeUri.startsWith('http://rdf.biogateway.eu/omim/')) {
      this.nodeType = 'disease';
    }

    this.loadSPARQLData();

  }

  loadMetadata() {
    this.network.getNodeMetadata(this.nodeUri, (metadata => {
      this.metadata = metadata;
      this.description = metadata.definition;
      let text = metadata.definition + '.';
      let synonyms = '';
      if (metadata.prefLabel) { this.label = metadata.prefLabel; }

      if (this.nodeType === 'gene') {

        if (metadata.ncbiDef) {
          this.description = metadata.ncbiDef;
        }


        if (metadata.synonyms) {
          text += '\n\nSynonyms: ' + metadata.prefLabel;
          for (const symbol of metadata.synonyms) {
            text += ', ' + symbol;
            synonyms += ' ' + symbol;
          }
        }
        this.tooltipText += text;
        this.synonymList = synonyms;
      }
    }));
  }

  loadSPARQLData() {
    const query = this.sparql.labelAndDescriptionString(this.nodeUri);
    const params = new HttpParams().set('query', query);
    console.log(query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      const firstResult = data.results.bindings[0];
      this.metadata = firstResult;
      this.label = firstResult.label.value;
      this.description = firstResult.definition.value;
      console.log('Label: ' + firstResult.label.value + ' Definition: ' + firstResult.definition.value);
    });
  }
}
