import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NodeViewComponent } from './node-view/node-view.component';
import {HttpClientModule} from '@angular/common/http';
import { StatementViewComponent } from './statement-view/statement-view.component';
import { NodeFragmentComponent } from './node-fragment/node-fragment.component';
import { ProtToGeneComponent } from './prot-to-gene/prot-to-gene.component';
import { GeneToPhenComponent } from './gene-to-phen/gene-to-phen.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ProtToProtComponent } from './prot-to-prot/prot-to-prot.component';
import { ProteinViewComponent } from './protein-view/protein-view.component';
import { GeneViewComponent } from './gene-view/gene-view.component';
import { SplitToArrayPipe } from './split-to-array.pipe';
import { InfoPageComponent } from './info-page/info-page.component';

@NgModule({
  declarations: [
    AppComponent,
    NodeViewComponent,
    StatementViewComponent,
    NodeFragmentComponent,
    ProtToGeneComponent,
    GeneToPhenComponent,
    SafeHtmlPipe,
    ProtToProtComponent,
    ProteinViewComponent,
    GeneViewComponent,
    SplitToArrayPipe,
    InfoPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
