import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NetworkService} from '../network.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-statement-view',
  templateUrl: './statement-view.component.html',
  styleUrls: ['./statement-view.component.css']
})
export class StatementViewComponent implements OnInit {

  nodeUri: string;
  statementDataResults: any;
  instanceResults: any;

  graphName = '?graph';

  nodeLabel: string;
  description: string;
  graphUri: string;

  constructor(public sparql: NetworkService, public httpClient: HttpClient, public route: ActivatedRoute) { }

  ngOnInit() {
    this.nodeUri = window.location.href.replace('https://', 'http://')
      .replace('localhost:4200', 'rdf.biogateway.eu')
      .replace('test.biogateway', 'rdf.biogateway');

    this.loadStatementData(this.nodeUri);
    this.loadDescription(this.nodeUri);
    this.loadInstances(this.nodeUri);
  }

  loadStatementData(uri: string) {
    const query = this.sparql.statementUriAndLabelsString(uri, this.graphName);
    console.log(query);
    const params = new HttpParams().set('query', this.sparql.statementUriAndLabelsString(uri, this.graphName));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      this.statementDataResults = data.results.bindings;
    });
  }

  loadDescription(uri: string) {
    const params = new HttpParams().set('query', this.sparql.labelAndDescriptionString(uri));

    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      const firstResult = data.results.bindings[0];
      const label = firstResult.label.value;
      const graph = firstResult.graph.value;
      this.nodeLabel = label;
//      this.description = firstResult.definition.value;
      this.graphUri = graph;
    });
  }

  loadInstances(uri: string) {
    const params = new HttpParams().set('query', this.sparql.instancesString(uri));
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      console.log(data);
      this.instanceResults = data.results.bindings;
    });
  }
}
