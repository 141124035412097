import { Component, OnInit } from '@angular/core';
import {NodeViewComponent} from '../node-view/node-view.component';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-protein',
  templateUrl: './protein-view.component.html',
  styleUrls: ['./protein-view.component.css',
    '../node-view/node-view.component.css']
})
export class ProteinViewComponent extends NodeViewComponent implements OnInit {

  functionList = [];
  geneMetadata: any;
  goTermsInvolvedInResults: any;
  goTermsPartOfResults: any;
  goTermsEnablesResults: any;
  encodedBy: any;
  targetGenes: any;
  ppiTargets: any;


  ngOnInit() {
    super.ngOnInit();
    this.loadMembers(this.nodeUri);
    this.loadInvolvedIn();
    this.loadEnables();
    this.loadPartOf();
    this.loadEncodedBy();
    this.getGeneMetadata();
    this.loadPPITargets();
    this.loadTargetGenes();
  }

  loadEncodedBy() {
    const query = this.network.nodesWithRelationToSourceInGraph(this.nodeUri, environment.relationTypes.encodes, environment.graphs.gene, environment.graphs.gene);
    const params = new HttpParams().set('query', query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      if (data.results.bindings[0]) {
        this.encodedBy = data.results.bindings;
      }
    });
  }

  loadTargetGenes() {
    const query = this.network.nodesWithRelationFromSourceInGraph(this.nodeUri, environment.relationTypes.tftgRegulates, environment.graphs.tfac2gene, environment.graphs.gene);
    const params = new HttpParams().set('query', query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      if (data.results.bindings[0]) {
        this.targetGenes = data.results.bindings;
      }
    });
  }

  loadPPITargets() {
    const query = this.network.nodesWithRelationFromSourceInGraph(this.nodeUri, environment.relationTypes.ppiInteracts, environment.graphs.prot2prot, environment.graphs.prot);
    const params = new HttpParams().set('query', query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      if (data.results.bindings[0]) {
        this.ppiTargets = data.results.bindings;
      }
    });
  }

  loadInvolvedIn() {
    const query = this.network.nodesWithRelationFromSourceInGraph(this.nodeUri, environment.relationTypes.involvedIn, environment.graphs.prot2onto, environment.graphs.go);
    const params = new HttpParams().set('query', query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      if (data.results.bindings[0]) {
        this.goTermsInvolvedInResults = data.results.bindings;
      }
    });
  }
  loadPartOf() {
    const query = this.network.nodesWithRelationFromSourceInGraph(this.nodeUri, environment.relationTypes.partOf, environment.graphs.prot2onto, environment.graphs.go);
    const params = new HttpParams().set('query', query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      if (data.results.bindings[0]) {
        this.goTermsPartOfResults = data.results.bindings;
      }
    });
  }
  loadEnables() {
    const query = this.network.nodesWithRelationFromSourceInGraph(this.nodeUri, environment.relationTypes.enables, environment.graphs.prot2onto, environment.graphs.go);
    const params = new HttpParams().set('query', query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      if (data.results.bindings[0]) {
        this.goTermsEnablesResults = data.results.bindings;
      }
    });
  }

  getGeneMetadata() {
    this.network.getGenesForProt(this.nodeUri, (geneData) => {
      this.geneMetadata = geneData[0][0];
    });
  }

}
