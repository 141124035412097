// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  biogwServerUrl: 'https://endpoint.biogateway.eu/sparql/',
  pubmedAbstractAPIURI: 'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi',
  metadatabaseSentencesAPI: 'https://meta.biogateway.eu/getGenexMetadataFromIDs',
  metadatabaseFetchAPI: 'https://meta.biogateway.eu/fetch',
  metadatabaseGenesFromProt: 'https://meta.biogateway.eu/genesFromProt',
  relationTypes: {
    involvedIn: '<http://purl.obolibrary.org/obo/RO_0002331>',
    enables: '<http://purl.obolibrary.org/obo/RO_0002327>',
    partOf: '<http://purl.obolibrary.org/obo/BFO_0000050>',
    prefLabel: 'skos:prefLabel',
    encodes: '<http://semanticscience.org/resource/SIO_010078>',
    ppiInteracts: '<http://purl.obolibrary.org/obo/RO_0002436>',
    tftgRegulates: '<http://purl.obolibrary.org/obo/RO_0002428>'
  },
  baseUri: 'http://rdf.biogateway.eu',
  graphs: {
    prot: 'http://rdf.biogateway.eu/graph/prot',
    go: 'http://rdf.biogateway.eu/graph/go',
    prot2onto: 'http://rdf.biogateway.eu/graph/prot2onto',
    gene: 'http://rdf.biogateway.eu/graph/gene',
    tfac2gene: 'http://rdf.biogateway.eu/graph/tfac2gene',
    prot2prot: 'http://rdf.biogateway.eu/graph/prot2prot'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
