import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../environments/environment';
import * as X2JS from 'x2js';
import {catchError, tap} from 'rxjs/internal/operators';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(public httpClient: HttpClient) { }

  runSPARQLQuery(query: string) {
    const params = new HttpParams().set('query', query);

    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: Object) => {
        console.log(data);
    });

  }



  public getMatchedSentencesForAbstract(pubmedId: string, tf: string, tg: string, callback: (statements: any[]) => void) {
    const params = new HttpParams()
      .set('tf', tf)
      .set('tg', tg)
      .set('pubmedId', pubmedId);
    this.httpClient.get(environment.metadatabaseSentencesAPI, {params: params}).subscribe((results: any) => {

      const sentences = [];

      for (const data of results) {
        const sentence = {
          pubmedId: pubmedId,
          tf: data.TF,
          tg: data.TG,
          score: data.score,
          startPosition: data.sentencePos,
          text: data.sentence
        };
        sentences.push(sentence);
      }
      callback(sentences);
    });
  }

  public getNodeMetadata(nodeUri: string, callback: (metadata: any) => void) {
    this.httpClient.post(environment.metadatabaseFetchAPI, {
      uris: [nodeUri]
    }).subscribe((data: any) => {
      if (data && data.length > 0) {
        callback(data[0]);
      }
    });
  }

  public getGenesForProt(nodeUri: string, callback: (metadata: any) => void) {
    this.httpClient.post(environment.metadatabaseGenesFromProt, {
      uris: [nodeUri]
    }).subscribe((data: any) => {
      if (data) {
        callback(data);
      }
    });
  }

  public getAbstractForPubmedId(pubmedId: string, callback: (abstract: any) => void) {
    const params = new HttpParams()
      .set('db', 'pubmed')
      .set('tool', 'BioGateway')
      // .set('email', 'stian.holmas@ntnu.no')
      .set('retmode', 'xml')
      .set('id', pubmedId);
    this.httpClient.get(environment.pubmedAbstractAPIURI, {params : params, responseType: 'text'}).pipe(
         tap(
           data => {
           },
           error => {
             console.log(error);
           }
         )).subscribe((data: any) => {

      const x2js = new X2JS();
      const jsonObj = x2js.xml2js(data) as any;
      const article = jsonObj.PubmedArticleSet.PubmedArticle.MedlineCitation.Article;
      let abstractText = article.Abstract.AbstractText;
      const title = article.ArticleTitle;

      if (typeof abstractText === 'object') {
        abstractText = this.renderAbstractObject(abstractText);
      }

      const abstractObj = {
        pubmedId: pubmedId,
        title: title,
        text: abstractText,
        maxScore: 0
      };

      callback(abstractObj);
    });
  }

  public getRelatedResourcesQuery(uri: string, graph: string) {
    const query = 'BASE <http://rdf.biogateway.eu/graph/>  \n' +
      'SELECT DISTINCT ?resource\n' +
      'WHERE { GRAPH ' + graph + ' {\n' +
      '?inst rdf:type <' + uri + '> .\n' +
      '?inst <http://purl.obolibrary.org/obo/BFO_0000050> ?resource .\n' +
      '}}';

    console.log('Query: ' + query);
    return query;
  }

  public urisWithRelationTo(uri: string, relation: string, graph: string, reverse: boolean) {
    let relationUri = '<' + relation + '>';
    if (reverse) { relationUri = '^' + relationUri; }
    return 'BASE <http://rdf.biogateway.eu/graph/>' +
      'SELECT distinct ?uri \n' +
      'WHERE {  \n' +
      'GRAPH ' + graph + ' {  \n' +
      '<' + uri + '> ' + relationUri + ' ?uri .\n' +
      '\t}}';
  }

  public getUrisWithRelationTo(uri: string, relation: string, graph: string, reverse: boolean, callback: (data: any) => void) {
    let relationUri = '<' + relation + '>';
    if (reverse) { relationUri = '^' + relationUri; }
    const query = 'BASE <http://rdf.biogateway.eu/graph/>' +
      'SELECT distinct ?uri \n' +
      'WHERE {  \n' +
      'GRAPH ' + graph + ' {  \n' +
      '<' + uri + '> ' + relationUri + ' ?uri .\n' +
      '\t}}';

    console.log(query);
    const params = new HttpParams().set('query', query);
    this.httpClient.get(environment.biogwServerUrl, {params: params}).subscribe((data: any) => {
      callback(data);
    });
  }



  public nameAndDescriptionNodesWithRelationFrom(uri: string, relation: string, graph: string, reverse: boolean) {
    let relationUri = '<' + relation + '>';
    if (reverse) { relationUri = '^' + relationUri; }
    return 'BASE <http://rdf.biogateway.eu/graph/>' +
    'SELECT distinct ?uri ?label ?definition\n' +
      'WHERE {  \n' +
      'GRAPH ' + graph + ' {  \n' +
      '?uri ' + relationUri + ' <' + uri + '> .\n' +
      '?uri skos:prefLabel ?label .\n' +
      '?uri skos:definition ?definition .\n' +
      '\t}}';
  }

  public protMembersString(uri: string) {
    return this.nameAndDescriptionNodesWithRelationFrom(uri, 'http://schema.org/memberOf', '<prot>', false);
  }

  public memberOfProtString(uri: string) {
    const query = this.nameAndDescriptionNodesWithRelationFrom(uri, 'http://schema.org/memberOf', '<prot>', true);
    console.log(query);
    return query;
  }

  public instancesString(uri: string) {
    return 'SELECT distinct ?graph ?instance\n' +
      'WHERE { GRAPH ?graph {\n' +
      '?instance rdf:type <' + uri + '> . \n' +
      '}}';
  }

  public superClassString(uri: string) {
    return 'SELECT distinct ?graph ?superclass\n' +
      'WHERE { GRAPH ?graph {\n' +
      '<' + uri + '> rdfs:subClassOf ?superclass . \n' +
      '}}';
  }

  public subClassString(uri: string) {
    return 'SELECT distinct ?graph ?subclass\n' +
      'WHERE { GRAPH ?graph {\n' +
      '?subclass rdfs:subClassOf <' + uri + '> . \n' +
      '}}';
  }

  public labelAndDescriptionString(uri: string) {
    return 'SELECT distinct ?graph ?label ?definition \n' +
      'WHERE {  \n' +
      'GRAPH ?graph {  \n' +
      'OPTIONAL { <' + uri + '> skos:prefLabel ?label . } \n' +
      'OPTIONAL { <' + uri + '> skos:altLabel ?label . } \n' +
      'OPTIONAL { <' + uri + '> skos:definition ?definition . } \n' +
      '}\n' +
      '}';
  }

  public nodesWithRelationFromSourceInGraph(sourceUri: string, relation: string, graph: string, labelGraph: string) {
    return 'SELECT distinct ?uri ?label \n' +
      'WHERE {  \n' +
      `GRAPH <${graph}> {  \n` +
      `<${sourceUri}> ${relation} ?uri . } \n` +
      `GRAPH <${labelGraph}> {  \n` +
      `?uri ${environment.relationTypes.prefLabel} ?label . } \n` +
      '}\n';
  }

  public nodesWithRelationToSourceInGraph(sourceUri: string, relation: string, graph: string, labelGraph: string) {
    return 'SELECT distinct ?uri ?label \n' +
      'WHERE {  \n' +
      `GRAPH <${graph}> {  \n` +
      `?uri ${relation} <${sourceUri}>  . } \n` +
      `GRAPH <${labelGraph}> {  \n` +
      `?uri ${environment.relationTypes.prefLabel} ?label . } \n` +
      '}\n';
  }

  public goTermsForProtString(protUri: string, relation: string) {
    return this.nodesWithRelationFromSourceInGraph(protUri, relation, environment.graphs.prot2onto, environment.graphs.go);
    // return 'SELECT distinct ?uri ?label \n' +
    //   'WHERE {  \n' +
    //   `GRAPH <${environment.graphs.prot2onto}> {  \n` +
    //   `<${protUri}> ${relation} ?uri . } \n` +
    //   `GRAPH <${environment.graphs.go}> {  \n` +
    //   `?uri ${environment.relationTypes.prefLabel} ?label . } \n` +
    //   '}\n';
  }

  public statementUriAndLabelsString(uri: string, graph: string) {
    return 'BASE <http://rdf.biogateway.eu/graph/> \n' +
      'PREFIX node: <' + uri + '>\n' +
      'SELECT DISTINCT ?subject ?subjectName GROUP_CONCAT(DISTINCT ?predicate;separator=\';\') as ?predicates GROUP_CONCAT(DISTINCT ?predicateName;separator=\';\') as ?predicateNames ?object ?objectName \n' +
      'WHERE {  \n' +
      'GRAPH ' + graph + ' {  \n' +
      '  node: rdf:subject ?subject .\n' +
      '  node: rdf:object ?object .\n' +
      '  node: rdf:predicate ?predicate .\n' +
      ' }\n' +
      ' OPTIONAL { ?subject skos:prefLabel ?subjectName . }\n' +
      ' OPTIONAL { ?subject skos:altLabel ?subjectName . }\n' +
      ' OPTIONAL { ?object skos:prefLabel ?objectName . }\n' +
      ' OPTIONAL { ?object skos:altLabel ?objectName . }\n' +
      ' OPTIONAL { ?predicate skos:prefLabel ?predicateName . }\n' +
      '}';
  }



  public referenceQueryString(uri) {
    return 'BASE <http://rdf.biogateway.eu/graph/> \n' +
      'PREFIX statement: <' + uri + '>\n' +
      'SELECT DISTINCT ?val \n' +
      'WHERE {  \n' +
      'GRAPH ?graph {\n' +
      'statement: <http://semanticscience.org/resource/SIO_000772> ?val .\n' +
      '}}';
  }

  private renderAbstractObject(obj) {
    let abstractText = '';

    for (const item of obj) {
      const label = item._Label;
      const text = item.__text;

      abstractText += '<h4>' + label + '</h4>' + text;
    }
    return abstractText;
  }

}
